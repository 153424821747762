@import url(https://fonts.googleapis.com/css?family=VT323);

* {
  /* border: 2px solid red; */
  align-content: center;
  margin: auto;
}
/* Main Pages/Elements */
.body {
  display: flex;
  background-color: #6ED8A5;
  align-items: center;
}

.title {
  font-size: 80px;
  text-align: center;
  font-family: 'Times New Roman', Times, serif
}

/*about*/

.aboutPage {
  background-color: white;
}

.aboutTxtShell {
  justify-content: center;
}

.picShell {
  text-align: center;
}

.about {
  font-size: 20px;
  color: #2B2D50;
  border: solid #2B2D50;
  /* #2B2D50 5px; */
  padding: 20px;
  margin-top: 20px;
  border-radius: 3%;
  /* font-weight: bold; */
  width: 80%;
  background-color: #AFB1D4;
}

.aboutPic {
  width: 20%;
  border-radius: 20%;
}

.pAbout{
  font-family:'Times New Roman', Times, serif;
  width: 80%;
  padding: 20px 0px;
}

/*home*/
.home {
  background-color: black;
  color: #33ff00;
  font-family: "VT323", monospace;
  /* display: flex; */
  margin: 30px auto;
  justify-content: center;
  align-items: center;
}

.homeDiv{
  /* margin: auto; */
  /* text-align: center; */
  width: 75%;
  justify-content: center;
  align-items: center;
}

.footDiv{
  /* display: flex; */
  justify-content: space-between;
  text-align: center;
}

.homeTxt {
  padding: 0 0 2% 0;
}

.homeOne {
  font-size: 70px;
  /* text-align: left; */
}

.home3 {
  font-size: 40px;
  margin: 5%;
  /* text-emphasis: left; */
}

.homeFoot {
  display: flex;
  align-items: center;

}

.homeFootLink {
  /* justify-content: space-around; */
  align-items: center;
  text-align: center;
}

.homeButton {
  align-items: center;
  /* justify-items: space-around; */
}

.figs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.icon {
  height: 50px;
}

.inline {
  display: flex;
  justify-content: space-between;
}

li {
  border: #33ff00;
  border-width: 5px;
}

.Pix {
  width: 100%;
}



/* Home Nav */
.nav {
  /* display: flex;
  justify-content: space-between; */
}



.homeMenu {
  background-color: #33ff00;
  width:10%;
  /* justify-content: space-between; */
  border: 6px inset rgba(0, 0, 0, 0.5);
  margin: 5px;
  text-align: center;
}

.homeMenu:hover, .homeIcon:hover{
  opacity: 70%;
}

/* .homeIcon{
  margin: 5px;
} */
.homeMenTxt {
  font-family: "VT323", monospace;
  text-decoration: none;
  color: black;
  font-size: x-large;
}

.botText {
  font-size: 30px;
}

a {
  text-decoration: none;
  color: black;
}
/* home nav icons */
.connectPic {
  height: 3vmin;
  background-color: #33ff00;
}

.homeButton a {
  color: #33ff00;
  text-decoration: none;
}




/* Portfolio */

/* .portfolioPage{
  background-color: #DFE5E8;
} */

.portfolio {
  font-family: 'Times New Roman', Times, serif;
  color: #334147;
  margin-top: 20px;
  text-align: center;
  width: 80%;
  background-color: #DFE5E8;
  border-radius: 3%;
  border: 6px solid #334147;
}

.card {
  text-align: left;
  padding-left: 5%;
  padding-right: 5%;
  background-color: #DFE5E8;
  color: #334147;
}

.card1 {
  text-align: left;
  font-size: 40px;
  padding-bottom: 20px;
  padding-top: 10px;
}

.card3 {
  padding-bottom: 15px;
}

.cardImages {
  padding-top: 20px;
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
}

.cardImg {
  margin-bottom: 10px;
  width: 45%;
  border: solid #334147;

}

.cardImg:hover {
  transform: scale(1.5);
  /* border: none; */
}

.card1Link:hover {
  color: #334147;
  text-decoration: underline;
  cursor: pointer;
}

.card1Link {
  color: #718D98;
}

/* Tabs  */



.react-tabs__tab-list {
  /* margin: 0 auto 2rem; */
  display: flex;
  /* justify-content: left; */
  /* transition: all 0.7s; */
  border: 3px solid #12402B;
  /* background-image: linear-gradient(to right,  #6ED8A5, #0D3020); */
  
}

.react-tabs__tab {
  list-style: none;
  cursor:pointer;
  padding-top: 2%;
  padding-left: 5%;
  border-right: 3px solid #12402B;
  border-top-right-radius: 40%;
  margin:0%
}

.react-tabs__tab:hover {
  /* background-image: linear-gradient(to right,  #6ED8A5, #0D3020); */
  color: #9EE5C3;
  background-color: #0D3020;
}

.react-tabs__tab--selected {
  background-color: #334147;
  color: #DFE5E8;
}

/* resume */

.resume{
width: 70%;
border: solid 3px #12402B;
/* #A2535F; */
border-radius: 3%;
margin-top: 20px;
text-align: center;
background-color: #6ED8A5;
/* #EAD6D9; */
color: #12402B;
/* #A2535F; */
}

/* .resumePage{
  background-color: pink;
} */

.resSliderDivk{
  width: 80%;
  align-items: center;
  margin: auto;
}

.resumeButton {
  width: 50%;
}

.resPic{
  width: 70%
}
.downButton{
  margin-bottom: 10px;
}

.swipeButtons{
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

